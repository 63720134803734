.duck-profile-container {
  padding: 2em;
}

.duck-image,
.duck-name-header {
  margin-bottom: 1em;
}

.duck-name-header {
  text-align: center;
}

.duck-bio-segment,
.details-segment,
.details-list,
.more-details-button {
  padding: 2em;
}

.details-list {
  list-style-type: none;
}

.details-list-item {
  margin-bottom: 0.5em;
}

.more-details-button {
  margin-right: 1em;
}

.orange-gray-gradient-button {
  background: linear-gradient(to right, #f2711c, #808080);
  color: white;
  border: none;
}

.orange-gray-gradient-button:hover {
  background: linear-gradient(to right, #e6600d, #707070);
}

.bottom-spaced-card,
.main-duck-card {
  margin-top: 20px;
}

.large-text {
  font-size: 15px;
}

/* Styles for the scrollable map cards container */
.map-cards-container {
  display: flex;
  overflow-x: auto;
  gap: .5rem;
  padding: 1em 0;
}

.map-card {
  flex: 0 0 auto;
  width: 300px; /* Adjust as needed */
}

.map-card .leaflet-container {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Styles for new ducks buttons */
.new-duck-buttons {
  position: relative;
  margin: 1em;
}

.card-with-buttons {
  overflow: hidden;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .duck-profile-container {
    padding: 1em;
  }

  .duck-image img,
  .duck-bio-segment,
  .map-cards-group,
  .more-details-button,
  .back-button {
    width: 100%;
  }

  .duck-image img {
    margin-bottom: 0.5em;
  }

  .duck-bio-segment {
    order: 3;
    padding: 1em;
    margin-bottom: 1em;
  }

  .map-cards-group {
    order: 4;
    padding: 0;
    margin-top: 1em;
    justify-content: center;
  }

  .map-cards-group .map-card {
    margin-right: 0;
  }

  .more-details-button,
  .back-button {
    margin-bottom: 1em;
  }
}

/* Ensure buttons are contained within the card for new ducks */
.new-duck-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  button {
    margin-top: 0.5em;
  }
}
