.not-found-page {
    height: 100vh;
    overflow: hidden;
    text-align: center;
}

.content {
    margin-top: 50px;
}

.not-found-page h1 {
    font-size: 36px;
    color: #333;
}

.not-found-page p {
    font-size: 18px;
    color: #666;
    margin: 20px 0;
}

.home-link {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    margin-bottom: 30px;
}

.home-link:hover {
    background-color: #0056b3;
}
